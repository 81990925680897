<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="wehed()">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="https://www.fiamgroup.com/wp-content/uploads/2020/07/12.-inglese-1.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Straight pistol and angel electric screwdrivers </h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="thnine()">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%; margin: auto"
                class="card-img-top img-fluid"
                src="https://www.fiamgroup.com/wp-content/uploads/2020/07/21.-106-ing.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Electric screwdriver with automatic screw feed</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="thletha()">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%; margin: auto"
                class="card-img-top img-fluid"
                src="https://www.fiamgroup.com/wp-content/uploads/2020/07/28-106-sp.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">MCA electric tightening modules</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="arb3a()">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 100%; margin: auto"
                class="card-img-top img-fluid"
                src="https://www.fiamgroup.com/wp-content/uploads/2020/06/31.-mix-cobot.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">MCA electric tightening modules for cobots</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
      </div>
          <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="khamssa()">
            <div class="card" style="width: 260px; height: 240px">
              <img
                style="max-height: 70%; max-width: 80%; margin: auto"
                class="card-img-top img-fluid"
                src="https://www.fiamgroup.com/wp-content/uploads/2020/07/32-106.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h4 style="text-align: center" class="card-title">Control Units</h4>
              </div>
            </div>
          </a>
        </div>
        <!-- end col -->
      
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    wehed() {

      window.location.href = "https://www.fiamgroup.com/en/products/straight-pistol-and-angle-electric-screwdrivers/";
    c
    },
    thnine() {
      window.location.href = "https://www.fiamgroup.com/en/products/electric-screwdrivers-with-automatic-screw-feed/";
     
    },
    thletha() {
      window.location.href = "https://www.fiamgroup.com/en/products/mca-electric-tightening-modules/";
  
    },
    arb3a() {
      window.location.href = "https://www.fiamgroup.com/en/products/mca-electric-tightening-modules-for-cobots/";
 
    },
    khamssa() {
      window.location.href = "https://www.fiamgroup.com/en/products/control-units/";

    },
  },
};
</script>